import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TConditionalFormula, TFormulaPiece } from './utils/formula-input.type';
import { buildFormula } from './utils/build-formula';
import { deconstructConditionalFormula, deconstructFormula } from './utils/deconstruct-formula';
import { translate } from '../../features/translate/translate';
import { EIconPath } from '../../shared/enums/icons.enum';
import { TTranslationKey } from '../../features/translate/types';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { ApiService } from '@core/http';

@Component({
  selector: 'pp-formula-builder',
  templateUrl: './formula-builder.component.html',
  styleUrl: './formula-builder.component.scss',
})
export class FormulaBuilderComponent {
  constructor(private apiService: ApiService) {}

  EIconPath: EIconPath;

  @Input() ppWorkspaceId: string;
  @Input() ppFormula: string;
  @Input() ppFieldId: string;
  @Output() ppFormulaChange = new EventEmitter<string>();

  conditionalFormula: TConditionalFormula = { condition: [], truthy: [], falsey: [] };
  formula: TFormulaPiece[] = [];

  conditionalFormulaOpen: string = null;

  conditionalFormulaConditionPlaceholder = translate(
    'conditional_formula_logical_test_placeholder',
  );

  conditionalFormulaTruthyPlaceholder = translate('conditional_formula_truthy_placeholder');

  conditionalFormulaFalsyPlaceholder = translate('conditional_formula_falsey_placeholder');

  isConditional: boolean;
  tooltip: string = '';
  tooltipPreviewText = '';

  ngOnInit(): void {
    if (this.ppFormula.startsWith('if')) {
      this.conditionalFormula = deconstructConditionalFormula(this.ppFormula);
      this.isConditional = true;

      this.updateTooltipMessage();
    } else {
      this.isConditional = false;
      this.formula = deconstructFormula(this.ppFormula);
      this.updateTooltipMessage();
    }
  }

  toggleIsConditional(isConditional: boolean) {
    this.isConditional = isConditional;

    if (!this.isConditional) {
      this.ppFormulaChange.emit(buildFormula(this.formula));
    } else {
      this.updateCompleteConditionalFormula();
    }

    this.updateTooltipMessage();
  }

  updateFormula(formula: TFormulaPiece[]): void {
    this.formula = formula;
    this.ppFormulaChange.emit(buildFormula(formula));
  }

  //Should guard cases where you switch from formula to conditional and where values are missing in conditional
  updateCompleteConditionalFormula(): void {
    if (
      this.conditionalFormula.condition.length &&
      this.conditionalFormula.truthy.length &&
      this.conditionalFormula.falsey.length
    ) {
      this.ppFormulaChange.emit(buildFormula(this.conditionalFormula));
    } else {
      this.ppFormulaChange.emit('');
    }
  }

  updateConditionFormula(formula: TFormulaPiece[]): void {
    this.conditionalFormula.condition = formula;
    this.updateCompleteConditionalFormula();
  }

  updateTruthyFormula(formula: TFormulaPiece[]): void {
    this.conditionalFormula.truthy = formula;
    this.updateCompleteConditionalFormula();
  }

  updateFalseyFormula(formula: TFormulaPiece[]): void {
    this.conditionalFormula.falsey = formula;
    this.updateCompleteConditionalFormula();
  }

  expandConditionalFormulaField(id: string): void {
    this.conditionalFormulaOpen = id;
  }

  async updateTooltipMessage() {
    if (this.isConditional) {
      this.tooltip = await this.generateExampleConditionalFormulas();
      this.tooltipPreviewText = translate('view_conditional_example');
    } else {
      this.tooltip = await this.generateExampleStandardFormulas();
      this.tooltipPreviewText = translate('view_standard_example');
    }
  }

  async generateExampleStandardFormulas(): Promise<string> {
    const [costCF, percentageCF, newCostCF, cost1CF] = await Promise.all([
      this.generateExampleTooltipCF('cost', EIconPath.ICON_FIELDS_COST_14),
      this.generateExampleTooltipCF('percentage', EIconPath.ICON_FIELDS_COST_14),
      this.generateExampleTooltipCF('new_cost', EIconPath.ICON_FIELDS_COST_14),
      this.generateExampleTooltipCF('cost1', EIconPath.ICON_FIELDS_COST_14),
    ]);

    return `<div class="exampleTooltipFormulaContainer"> 
      <span>${translate('formula_example_1')}</span>
      <span>${costCF} * ${percentageCF} = ${newCostCF}</span> 
      <span>${translate('formula_example_2')} </span>
      <span>( ${costCF} + ${cost1CF} ) / 2 = ${newCostCF}</span>
       </div>`;
  }

  async generateExampleConditionalFormulas(): Promise<string> {
    const [shipyardQuoteText, estimateText] = await Promise.all([
      this.generateExampleTooltipCF('shipyard_quote', EIconPath.ICON_FIELDS_COST_14),
      this.generateExampleTooltipCF('estimate', EIconPath.ICON_FIELDS_COST_14),
    ]);

    return `
      <div class="exampleTooltipFormulaContainer">
        <span>${translate('conditional_formula_condition_label')}</span>
        <span>${shipyardQuoteText} = 0</span>
        <span>${translate('conditional_formula_truthy_label')}</span>
        <span>${estimateText}</span>
        <span>${translate('conditional_formula_falsy_label')}</span>
        <span>${shipyardQuoteText}</span>
      </div>
    `;
  }

  async generateExampleTooltipCF(value: TTranslationKey, icon: EIconPath): Promise<string> {
    const svg = await getSvg(this.apiService, icon);
    return `<div class="exampleTooltipFormulaCustomField">
            ${svg}
            <span>${translate(value)}</span>
          </div>`;
  }
}
