import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TFormulaPiece } from '../utils/formula-input.type';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'pp-formula-builder-field',
  templateUrl: './formula-builder-field.component.html',
  styleUrl: './formula-builder-field.component.scss',
  animations: [
    trigger('openCloseValues', [
      state(
        'open',
        style({
          height: '*',
        }),
      ),
      state(
        'closed',
        style({
          height: '0',
        }),
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
    trigger('openCloseInput', [
      state(
        'open',
        style({
          height: '*',
        }),
      ),
      state(
        'closed',
        style({
          height: '32px',
        }),
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class FormulaBuilderFieldComponent {
  @Input() ppWorkspaceId: string;
  @Input() ppFormula: TFormulaPiece[];
  @Input() ppFieldId: string;
  @Input() expandable: boolean = false;
  @Input() ppIsConditional: boolean = false;
  @Input() isOpen: boolean = true;
  @Input() ppPlaceholder = '';
  @Output() ppFormulaChange = new EventEmitter<TFormulaPiece[]>();
  @Output() ppExpand = new EventEmitter();

  toggleIsOpen(): void {
    this.ppExpand.emit();
  }

  selectOperator(operator: TFormulaPiece): void {
    this.ppFormula.push(operator);

    this.updateFormula(this.ppFormula);
  }

  updateFormula(formula: TFormulaPiece[]): void {
    this.ppFormulaChange.emit(formula);
  }
}
